import React from "react";
import _ from "lodash";
import { getPanoramaByIndex } from "../helpers/utils";

class ShowPanoramas extends React.Component {
  constructor(props) {
    super(props);

    const { finalData } = props;
    const { panoramas } = finalData;

    this.state = {
      detectedFaceShape: props.shape || null,
      showFrame: null,
      framesCount: _.sumBy(panoramas, "cnt"),
      renderBoxSize: null
    };

    console.log("ShowPanoramas");
    console.log(props);
    console.log(this.state);

    this.canvasRef = React.createRef();
  }

  componentDidMount() {
    var renderBoxParent = document.getElementsByClassName(
        "Virtual-Mirror-Content"
      )[0],
      renderBoxHeight = renderBoxParent.offsetHeight,
      renderBoxWidth = renderBoxParent.offsetWidth;

    const renderBoxSize = Math.min(720, renderBoxHeight, renderBoxWidth);
    this.setState({
      renderBoxSize
    });

    setTimeout(() => {
      const { finalData } = this.props;
      const { center } = finalData;
      this.renderPanorama(center);
    }, 250);
  }

  onMouseMove = e => {
    // const { finalData } = this.props;
    // const { panoramas, shape } = finalData;

    let bound = this.canvasRef.current.getBoundingClientRect();
    let xpos =
      (e.clientX || (e.touches && e.touches[0].clientX)) -
      bound.left -
      this.canvasRef.current.clientLeft;

    // console.log( e.target.parentElement.offsetWidth , this.canvasRef.current.offsetWidth)
    // console.log(e.clientX)
    // console.log((e.clientX || (e.touches && e.touches[0].clientX)))
    // console.log( e.target.offsetLeft)

    let frame_index = Math.floor(
      xpos / (this.canvasRef.current.offsetWidth / this.state.framesCount)
    );
    this.checkMustRender(frame_index >= 0 ? frame_index : 0);
  };

  clearCanvas(context, canvas) {
    context.clearRect(0, 0, canvas.width, canvas.height);
    const w = canvas.width;
    canvas.width = 1;
    canvas.width = w;
  }

  checkMustRender = frame_index => {
    const { showFrame } = this.state;

    if (showFrame !== frame_index) {
      // console.log("Change frame to => frame_index", frame_index);
      this.setState(
        {
          showFrame: frame_index
        },
        () => {
          this.renderPanorama(this.state.showFrame);
        }
      );
    }
  };

  renderPanorama = show_frame_index => {
    const { finalData } = this.props;
    const { panoramas } = finalData;
    const { renderBoxSize } = this.state;

    console.log("Zlecono renderowanie panoramy", show_frame_index);

    if (!panoramas) {
      console.log("Brak ppppp");
      return;
    }

    try {
      var panorama = getPanoramaByIndex(panoramas, show_frame_index);
      if (!panorama) {
        console.log("Brak ppppp");
        return;
      }

      console.log(panorama);

      var context = this.canvasRef.current.getContext("2d");
      console.log(context);
    } catch (err) {
      console.log(11111, err);
      setTimeout(() => {
        console.log("Wykonano opóźnienie");
        this.renderPanorama(show_frame_index);
      }, 250);
      return;
    }

    const image = new Image();
    image.onload = () => {
      requestAnimationFrame(() => {
        context.drawImage(
          image,
          0,
          720 * panorama[1],
          720,
          720,
          0,
          0,
          renderBoxSize,
          renderBoxSize
          // dimensions.width,
          // dimensions.width  * panoramas[panorama[0]].cnt,
          //  ( renderBoxSize - 720 )/2
        );
      });
    };
    image.src = panoramas[panorama[0]].url;
  };

  requestAnimationFrame = callback => {
    const requestAnimationFrame =
      window.requestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.msRequestAnimationFrame;

    return requestAnimationFrame(callback);
  };

  render() {
    // const { finalData } = this.props, { panoramas } = finalData;
    const { renderBoxSize } = this.state;

    return (
      <div
        className="visual-component"
        style={{
          minHeight: "calc(100vh - 30px)"
        }}
      >
        <div
          style={{
            position: "relative",
            overflow: "hidden",
            height: renderBoxSize, //dimensions.height,
            width: renderBoxSize //dimensions.width
          }}
        >
          <canvas
            width={renderBoxSize}
            height={renderBoxSize}
            ref={this.canvasRef}
            onMouseMove={this.onMouseMove}
            onTouchMove={this.onMouseMove}
            style={{
              width: renderBoxSize,
              height: renderBoxSize,
              // maxWidth: "100%",
              transform: "scaleX(-1)",
              // position: "absolute",
              // top: (dimensions.height - dimensions.width) / 2,
              left: 0
            }}
          />
          {this.state.detectedFaceShape && (
            <div className="footer">
              <div>Wykryta twarz: {this.state.detectedFaceShape}</div>
            </div>
          )}
          {/* END SCREEN */}
        </div>
      </div>
    );
  }
}

export default ShowPanoramas;
