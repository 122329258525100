var sprite = {
  ERROR_GENERIC: [0, 1032],
  GOOD: [1232, 696],
  POSITION: [2128, 1800],
  SCALE_1: [4128, 4248],
  SHUTTER: [8576, 783.673],
  TURN_LEFT_TO_CENTER: [9559.673, 2160],
  TURN_LEFT: [11919.673, 1680],
  TURN_RIGHT: [13799.673, 1704],
  TURN_RIGHT_TO_CENTER: [15703.673, 1848],
  UPLOAD: [17751.673000000003, 2040]
};

export default sprite;
